<template>
  <div>
    meetingNotify
  </div>
</template>

<script>
export default {
name :"MeetingNotify"
}
</script>

<style>

</style>